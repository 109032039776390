import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { FULL_WIDTH } from '../../golbal-constants/sizes';
import { SERVICE, ServiceType } from '../../store/services/type';
import { onViewService } from '../../store/slices/utilSlice';
import { useAppDispatch } from '../../store/store';
import { onShare, prettyAddress, prettyServiceType } from '../../utils/serviceUtils';
import CustomCarousel, { getPlaceholderImage } from '../common/CustomCarousel';

//icons
import { BsEyeFill, BsFillHouseDoorFill, BsThreeDotsVertical } from 'react-icons/bs';
import { FaChurch, FaMapMarkerAlt } from 'react-icons/fa';
import { FiShare } from 'react-icons/fi';
import { IoRestaurant, IoStorefront } from 'react-icons/io5';
import { MdCancel, MdVerified } from 'react-icons/md';
import { RiHotelBedFill } from 'react-icons/ri';
import { isShareSuppored } from '../../utils/utils';
import { CONFIGS } from '../../configs/configs';

export const SERVICE_CARD_HEIGHT = 350;
export const SERVICE_CARD_WIDTH = isMobile ? FULL_WIDTH * 0.9 : 350;
interface ServiceCardProps {
  service: SERVICE;
  setClickedService: (service: SERVICE) => void;
}

const ServiceCard = ({ service, setClickedService }: ServiceCardProps) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useAppDispatch();
  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}/?service=${service.id}`;

  //use effect
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 1000);
      onClickTracker('Service address copied from the main service list');
    }
  }, [showAlert]);

  const onClickTracker = (label: string) => {
    ReactGA.event({
      category: '',
      action: 'Click',
      label: label,
    });
  };
  return (
    <>
      <Helmet>
        {/* <title>{service.serviceName}</title> */}
        <meta property="og:title" content={service.serviceName} />
        <meta property="og:description" content={service.serviceDescription} />
        <meta
          property="og:image"
          content={
            service.imagePaths.length > 0
              ? `${CONFIGS.FLASK_ENDPOINT}/${service.imagePaths[0]}.jpeg`
              : getPlaceholderImage(service.serviceType)
          }
        />
        <meta property="og:url" content={fullUrl} />
      </Helmet>
      <Card sx={{ width: SERVICE_CARD_WIDTH, height: SERVICE_CARD_HEIGHT, borderRadius: 10, shadow: 'lg' }}>
        <CardBody p={0}>
          <Box
            w={SERVICE_CARD_WIDTH}
            height={SERVICE_CARD_HEIGHT * 0.6}
            onClick={() => {
              //Needed to preview service when sharing
              navigate(`?service=${service.id}`, { replace: false });

              dispatch(onViewService(true));
              setClickedService(service);
              onClickTracker('Service card');
            }}
          >
            <CustomCarousel
              type={service.serviceType}
              items={service.imagePaths}
              width={SERVICE_CARD_WIDTH}
              height={SERVICE_CARD_HEIGHT * 0.6}
            />
          </Box>
          <Stack mt="6" spacing="3" pl={3} pr={2} w={SERVICE_CARD_WIDTH} height={SERVICE_CARD_HEIGHT * 0.35}>
            <Stack p={0} direction="row" display="flex" alignItems="center" justifyContent="space-between">
              <Text fontSize="20px" fontWeight="530" fontFamily="montserrat" color="black" noOfLines={1}>
                {service.serviceName}
              </Text>
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    variant="unstyled"
                    aria-label="Service details"
                    icon={<BsThreeDotsVertical style={{ color: 'red', fontSize: '18px', marginLeft: 30 }} />}
                    onClick={() => {}}
                  />
                </PopoverTrigger>
                <PopoverContent h="80px" w="200px">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <VStack align="start">
                      <Button
                        variant="unstyled"
                        style={{ width: '80%', height: '100%' }}
                        onClick={() => {
                          dispatch(onViewService(true));
                          setClickedService(service);
                        }}
                      >
                        <HStack>
                          <BsEyeFill color="#068FFF" />
                          <Text fontSize="14px" fontWeight="400" color="black">
                            View Service
                          </Text>
                        </HStack>
                      </Button>

                      <Button
                        variant="unstyled"
                        style={{ width: '80%', height: '100%' }}
                        onClick={() => {
                          // dispatch(onServiceRemovalRequest(true));
                          // setClickedService(service);
                        }}
                      >
                        <HStack>
                          <MdCancel color="#DA121A" />
                          <Text fontSize="14px" fontWeight="400" color="black">
                            Request Removal
                          </Text>
                        </HStack>
                      </Button>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Stack>
            <Stack direction="row" spacing={3} align="center">
              <FaMapMarkerAlt color="#808080" fontSize="14px" />
              <span
                onClick={() => {
                  setShowAlert(true);
                  copy(prettyAddress(service.serviceAddress) || '');
                }}
                style={{ color: '#808080' }}
              >
                {prettyAddress(service.serviceAddress)}
              </span>
            </Stack>
            {showAlert && (
              <Alert status="success" w={200} height={20} fontSize="12px" borderRadius={10} alignSelf="center">
                <AlertIcon w={3} h={3} />
                Address copied!
              </Alert>
            )}
          </Stack>
        </CardBody>
        <HStack
          sx={{
            position: 'absolute',
            top: '47%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: 3,
          }}
        >
          <ServiceIndicator serviceType={service.serviceType} />
          {isShareSuppored() && (
            <IconButton
              colorScheme="gray"
              aria-label="Share service"
              size="md"
              icon={<FiShare fontSize="18px" />}
              onClick={() => onShare(service, fullUrl)}
            />
          )}
        </HStack>
        <Box sx={{ position: 'absolute', top: 2, right: 4 }}>
          <MdVerified style={{ color: '#DA121A', fontSize: '25px' }} />
        </Box>
      </Card>
    </>
  );
};

export default ServiceCard;

export const ServiceIndicator = (props: { serviceType: ServiceType | undefined }) => {
  return props.serviceType ? (
    <Stack direction="row" className="service-icon" bgGradient="linear(red.100 0%, orange.100 50%, yellow.100 100%)">
      {serviceIcon(props.serviceType)}
      <Text color="#36454F" fontSize="14px" fontWeight="semibold">
        {prettyServiceType(props.serviceType)}
      </Text>
    </Stack>
  ) : (
    <></>
  );
};
export const serviceIcon = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.HOTEL:
      return <RiHotelBedFill style={{ color: '#DA121A' }} />;
    case ServiceType.RESTAURANT:
      return <IoRestaurant style={{ color: '#DA121A' }} />;
    case ServiceType.CHURCH:
      return <FaChurch style={{ color: '#DA121A' }} />;
    // case ServiceType.MOSQUE:
    //   return <FaMosque style={{ color: '#DA121A' }} />;
    case ServiceType.STORE:
      return <IoStorefront style={{ color: '#DA121A' }} />;
    case ServiceType.BARBER_SHOP:
    case ServiceType.HAIR_SALON:
      return hairSalonIcon();
    default:
      return <BsFillHouseDoorFill style={{ color: '#DA121A' }} />;
  }
};

const hairSalonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 50 50">
      <path
        fill="#DA121A"
        d="M34.781 6.664H27.83c-1.256.004-1.209 1.876 0 1.865h6.951v.957H27.83c-1.249-.011-1.203 1.86 0 1.865h6.951v.978H27.83c-1.249 0-1.203 1.87 0 1.865h6.951v.932H27.83c-1.223.009-1.203 1.881 0 1.887h6.951v.934H27.83c-1.223-.004-1.228 1.865 0 1.865h6.951v.932H27.83c-1.223.004-1.228 1.875 0 1.866h6.951v.955H27.83c-1.223-.011-1.228 1.858 0 1.865h6.951v.93H27.83c-1.223-.001-1.203 1.872 0 1.866h6.951v18.159c.004 2.767 4.207 2.717 4.219 0V3.89c-.012-1.264-1.05-2.862-2.758-2.867H27.83c-1.256.001-1.209 1.873 0 1.865l6.951.023v.93H27.83c-1.254-.006-1.207 1.865 0 1.866h6.951v.957zm-7.429 32.194C27.348 35.628 24 32.154 20 34.404V22.05L17.413 1.675c-.054-.539-.511-.666-.818-.675c-.317.009-.952.136-1.021.675L13 22.05v12.354c-4-2.25-7.24 1.246-7.241 4.429c.001 2.832 2.181 5.158 5.131 5.151c2.972.007 5.11-2.6 5.11-5.151V26h1v12.833c0 2.856 2.212 4.97 4.67 5.104c-.041 1.566.47 3.8 1.432 4.686c1.128 1.04 2.471-.29 1.92-1.373c-.532-1.008-1.054-1.605-.63-3.806c1.772-.82 2.956-2.546 2.96-4.586zm-16.348 2.541a2.542 2.542 0 0 1 0-5.085c1.386.008 2.515 1.145 2.525 2.544a2.552 2.552 0 0 1-2.525 2.541zm8.571-2.541a2.553 2.553 0 0 1 2.526-2.544a2.548 2.548 0 0 1 2.525 2.544a2.545 2.545 0 0 1-2.525 2.541a2.549 2.549 0 0 1-2.526-2.541z"
      />
    </svg>
  );
};
