import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  UnorderedList,
} from '@chakra-ui/react';
import ReactGA from 'react-ga4';
import { isMobile } from 'react-device-detect';

//icons
import { IoAddCircle } from 'react-icons/io5';
import { openUrl } from '../../utils/utils';

const ServicesHeader = () => {
  const onEmail = () => {
    const recipient = 'ourgezana@gmail.com';
    const subject = 'Gezana Service Registration';
    const body = `Please provide the following information:
                  1. Service name and Adress (required)
                  2. Contact Infos
                  3. Short discription
                  4. Social media links
                  5. Photos
                  `;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = mailtoLink;
    anchor.click();
    onClickTracker('Add Service - Email');
  };

  const onClickTracker = (label: string) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: label,
    });
  };

  return (
    <Box sx={{ width: '100%', height: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Heading as="h4" size={isMobile ? 'md' : 'lg'} color="#666666">
        Services
      </Heading>

      <Popover placement="bottom" closeOnBlur={false}>
        <PopoverTrigger>
          <Button
            variant="solid"
            sx={{
              h: isMobile ? '30px' : '40px',
              fontSize: isMobile ? '12px' : '16px',
              bg: 'bg.primary',
              borderRadius: 30,
              _hover: { backgroundColor: 'bg.primary' },
            }}
            style={{ color: 'white' }}
            leftIcon={<IoAddCircle style={{ color: '#fff' }} />}
            // commented out because no longer using database, manually adding services to json file to reduce expenses.
            // onClick={() => dispatch(onAddService(true))}
            onClick={() => {
              onClickTracker('Add Service');
            }}
          >
            Add Service
          </Button>
        </PopoverTrigger>

        <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
          <PopoverArrow bg="blue.800" />
          <PopoverCloseButton />
          <br />
          <PopoverBody>
            Please send us the service details via one of the options below.
            <UnorderedList>
              <ListItem>Service name and address (required)</ListItem>
              <ListItem>Contact Infos</ListItem>
              <ListItem>Short description</ListItem>
              <ListItem>Social medias</ListItem>
              <ListItem>Photos</ListItem>
            </UnorderedList>
          </PopoverBody>
          <br />
          <PopoverFooter border="0" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
            <ButtonGroup size="sm">
              <Button
                colorScheme="red"
                onClick={() => {
                  onClickTracker('Add Service - Instagram');
                  openUrl('https://www.instagram.com/gezana__');
                }}
              >
                Instagram
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClickTracker('Add Service - Facebook');
                  openUrl('https://www.facebook.com/people/Gezana-%E1%8C%88%E1%8B%9B%E1%8A%93/61551340276009/');
                }}
              >
                Facebook
              </Button>
              <Button colorScheme="green" onClick={onEmail}>
                Email
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ServicesHeader;
