import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { APP_HEADER_HEIGHT, FULL_WIDTH } from '../../golbal-constants/sizes';
import { useGetServicesQuery } from '../../store/services/servicesApi';
import { getStatesAndCities, groupServicesByCityAndState } from '../../utils/serviceUtils';
import Dropdown from './Dropdown';
import DropdownMobile from './DropdownMobile';
import ReactGA from 'react-ga4';

//icons
import { BiSearch, BiSearchAlt2 } from 'react-icons/bi';
import { onServiceSearch } from '../../store/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { capitalizedTypeServices } from '../../store/services/type';

interface SearchBarProps {
  city: string | undefined;
  state: string | undefined;
  serviceType: string | undefined;
  search: string | undefined;
  setCity: (e: React.SetStateAction<string | undefined>) => void;
  setState: (e: React.SetStateAction<string | undefined>) => void;
  setServiceType: (e: React.SetStateAction<string | undefined>) => void;
  setSearch: (e: React.SetStateAction<string | undefined>) => void;
  onSearch: () => void;
}

const SearchBar = (props: SearchBarProps) => {
  const { city, state, serviceType, search, setCity, setState, setServiceType, setSearch, onSearch } = props;

  //Hooks
  const dispatch = useAppDispatch();
  const { data: services } = useGetServicesQuery();
  const { searchServicesMode } = useAppSelector(state => state.util);

  //local state
  const [selectCities, setSelectCities] = useState<string[]>([]);
  const [selectStates, setSelectStates] = useState<string[]>([]);
  const [mapStatesToCities, setMapStatesToCities] = useState<{ state: string; cities: string[] }[]>([]);

  useEffect(() => {
    if (services) {
      const { cities, states } = getStatesAndCities(services, state);
      setSelectCities(cities);
      setSelectStates(states);
      setMapStatesToCities(groupServicesByCityAndState(services));
    }
  }, [services, state]);

  const onClickTracker = (label: string, category?: string) => {
    ReactGA.event({
      category: category ?? 'dropdown',
      action: 'Click',
      label: label,
    });
  };

  return (
    <VStack className="center" h={APP_HEADER_HEIGHT * 0.5}>
      {isMobile ? (
        <HStack w="100%" className="center" spacing={1} px={3}>
          <DropdownMobile
            value={city}
            onChange={e => {
              if (state) {
                let map = mapStatesToCities.filter(item => item.state === state);
                if (map && !map[0].cities.includes(e.toString())) {
                  setState(undefined);
                }
              }
              onClickTracker('Search by city');
              setCity(e.toString());
            }}
            select={{ text: 'City', options: selectCities }}
          />

          <DropdownMobile
            value={state}
            onChange={e => {
              if (city) {
                let map = mapStatesToCities.filter(item => item.state === e.toString());
                if (map && !map[0].cities.includes(e.toString())) {
                  setCity(undefined);
                }
              }
              onClickTracker('Search by state');
              setState(e.toString());
            }}
            select={{ text: 'State', options: selectStates }}
          />
          <DropdownMobile
            value={serviceType}
            onChange={e => {
              onClickTracker('Search by service type');
              setServiceType(e.toString());
            }}
            select={{ text: 'Type', options: capitalizedTypeServices() }}
          />
          <Box w={FULL_WIDTH * 0.2} h="100%" className="center">
            <IconButton
              aria-label="Search services"
              borderRadius={20}
              sx={{ bg: '#c0c0c0', width: '50px', height: '100%', _hover: { backgroundColor: '#c0c0c0' } }}
              icon={<BiSearchAlt2 fontSize="25px" color="white" />}
              onClick={() => dispatch(onServiceSearch(true))}
            />
          </Box>
        </HStack>
      ) : (
        <HStack w="100%" className="center">
          <InputGroup className="center" style={{ width: '500px', height: '40px' }}>
            <InputLeftElement pointerEvents="none">
              <BiSearch color="#808080" />
            </InputLeftElement>
            <Input
              placeholder="Search service"
              focusBorderColor="red.500"
              style={{ width: '100%', height: '100%', borderRadius: 20, paddingLeft: 50, backgroundColor: 'white' }}
              value={search}
              onChange={e => {
                onClickTracker('Search button clicked on search bar');
                setSearch(e.target.value);
              }}
            />
            <InputRightElement style={{ width: '80px' }}>
              <IconButton
                aria-label="Search services"
                borderRadius={20}
                sx={{ bg: '#DA121A', width: '90%', height: '90%', _hover: { backgroundColor: '#DA121A' } }}
                icon={<BiSearchAlt2 fontSize="20px" color="white" />}
                onClick={onSearch}
              />
            </InputRightElement>
          </InputGroup>

          <>
            <Dropdown
              value={city}
              onChange={e => {
                if (state) {
                  let map = mapStatesToCities.filter(item => item.state === state);
                  if (map && !map[0].cities.includes(e.toString())) {
                    setState(undefined);
                  }
                }
                onClickTracker('Search by city');
                setCity(e.toString());
              }}
              select={{ text: 'Select City', options: selectCities }}
            />
            <Dropdown
              value={state}
              onChange={e => {
                if (city) {
                  let map = mapStatesToCities.filter(item => item.state === e.toString());
                  if (map && !map[0].cities.includes(e.toString())) {
                    setCity(undefined);
                  }
                }
                onClickTracker('Search by state');
                setState(e.toString());
              }}
              select={{ text: 'Select State', options: selectStates }}
            />
            <Dropdown
              value={serviceType}
              onChange={e => {
                onClickTracker('Search by service type');
                setServiceType(e.toString());
              }}
              select={{ text: 'Service Type', options: capitalizedTypeServices() }}
            />
          </>
        </HStack>
      )}

      {searchServicesMode && <BasicUsage search={search} setSearch={setSearch} onSearch={onSearch} />}
    </VStack>
  );
};
export default SearchBar;

interface BasicUsageProps {
  search: string | undefined;
  setSearch: (e: React.SetStateAction<string | undefined>) => void;
  onSearch: () => void;
}
export const BasicUsage = ({ search, setSearch, onSearch }: BasicUsageProps) => {
  //Hooks
  const dispatch = useAppDispatch();

  return (
    <Modal isOpen={true} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={10}>
          <Input
            borderRadius="20px"
            paddingLeft="50px"
            variant="outline"
            placeholder="Search Service"
            h="50px"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </ModalBody>

        <HStack className="center" spacing={20} mt={10} mb={5}>
          <Button
            mr={3}
            onClick={() => {
              setSearch('');
              dispatch(onServiceSearch(false));
            }}
          >
            Cancel
          </Button>
          <Button w={100} borderRadius={20} colorScheme="blue" mr={3} onClick={onSearch}>
            Search
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
};
