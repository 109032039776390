import { Box, Button, Flex, Image, Stack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_HEADER_HEIGHT, FULL_WIDTH } from '../../golbal-constants/sizes';
import { ROUTE_PATHS } from '../../routes/RoutePaths';

interface AppHeaderProps {
  children?: JSX.Element;
}
const AppHeader = ({ children }: AppHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onHomePage = location.pathname === ROUTE_PATHS.HOME;
  return (
    <Flex
      as="header"
      className="header"
      style={{
        width: FULL_WIDTH,
        height: onHomePage ? APP_HEADER_HEIGHT : APP_HEADER_HEIGHT * 0.5,
        // backgroundColor: 'red',
      }}
    >
      <Stack
        style={{
          width: '100%',
          height: onHomePage ? '50%' : '100%',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: 30,
        }}
      >
        <Box
          width="45px"
          height="45px"
          as="button"
          bg="white"
          borderRadius="45px"
          alignItems="center"
          justifyContent="center"
          display="flex"
          onClick={() => navigate(ROUTE_PATHS.HOME)}
        >
          <Image w="80%" h="80%" objectFit="contain" src="/assets/two.png" alt="Gezana icon" borderRadius="base" />
        </Box>
        <Button
          variant="unstyled"
          sx={{ fontSize: '20px', fontFamily: 'alfa', color: '#4d4d4d', letterSpacing: '3px' }}
          onClick={() => navigate(ROUTE_PATHS.HOME)}
        >
          GEZANA
        </Button>
      </Stack>
      {children}
    </Flex>
  );
};

export default AppHeader;

export const HeaderIcon = () => {
  return (
    <svg width="114" height="250" viewBox="0 0 114 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Background">
        <path
          id="Vector 1"
          d="M22.287 5C15.4566 37.2913 21.0322 101.197 97.9777 98.4922"
          stroke="url(#paint0_linear_8_979)"
          stroke-width="40"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_8_979" x1="22.0469" y1="4.5785" x2="96.3688" y2="90.9574" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DA121A" stop-opacity="0" />
          <stop offset="0.41416" stop-color="#DA121A" />
          <stop offset="0.732163" stop-color="#FCDD09" />
          <stop offset="1" stop-color="#FCDD09" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
