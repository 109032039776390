import { Box, HStack, Text, VStack, Alert, AlertIcon } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
//icons
import { FaLocationDot } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';

interface ViewServiceHeaderProps {
  name: string | undefined;
  address: string | undefined;
  phoneNumber: number | undefined | undefined;
  email: string | undefined | undefined;
}
const ViewServiceHeader = ({ name, address, phoneNumber, email }: ViewServiceHeaderProps) => {
  const [showAlert, setShowAlert] = useState(false);

  //use effect
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 1000);
    }
  }, [showAlert]);

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="space-between" mt={3} pl={5}>
      <VStack width="70%" height="100%" display="flex" alignItems="flex-start" justifyContent="center">
        <Text fontSize="24px" fontFamily="montserrat" fontWeight="600" noOfLines={1}>
          {name}
        </Text>
        <VStack>
          <HStack>
            <FaLocationDot color="gray" />
            <Text fontSize="14px" fontWeight="400" color="gray" noOfLines={2}>
              <span
                onClick={() => {
                  setShowAlert(true);
                  copy(address || '');
                }}
              >
                {address}
              </span>
            </Text>
          </HStack>
          {showAlert && (
            <Alert status="success" w={200} height={5} fontSize="12px" borderRadius={10} alignSelf="center">
              <AlertIcon w={3} h={3} />
              Address copied!
            </Alert>
          )}
        </VStack>
      </VStack>
      <VStack spacing={0} width="30%" height="100%" display="flex" alignItems="flex-start" justifyContent="center">
        {phoneNumber && (
          <HStack>
            <BsFillTelephoneFill color="gray" />
            <Text fontSize="14px" fontWeight="400" color="black">
              {phoneNumber}
            </Text>
          </HStack>
        )}
        {email && (
          <HStack width="100%" height="30%">
            <MdEmail color="gray" />
            <Text fontSize="14px" fontWeight="400" color="black">
              {email}
            </Text>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default ViewServiceHeader;
