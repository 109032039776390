import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AboutContainer from './components/about/AboutContainer';
import AppContainer from './components/common/AppContainer';
import AppHeader from './components/common/AppHeader';
import { PageNotFound } from './components/error-page/PageNotFound';
import FAQContainer from './components/faq/FAQcontainer';
import FooterContainer from './components/footer/FooterContainer';
import HomeContainer from './components/services/ServicesContainer';
import { ScrollToTop } from './components/utils/ScrollToTop';
import { ROUTE_PATHS } from './routes/RoutePaths';
import ReactGA from 'react-ga4';
import { isMobile, isTablet } from 'react-device-detect';

const App = () => {
  const location = useLocation();

  const showSearchAppHeader = location.pathname !== ROUTE_PATHS.HOME;

  useEffect(() => {
    // Identify device on which the website is opened.
    if (!localStorage.getItem('deviceTracked')) {
      const deviceType = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Laptop';
      ReactGA.event({
        category: 'Device',
        action: 'Accessed',
        label: deviceType,
      });

      // Store a flag in localStorage
      localStorage.setItem('deviceTracked', 'true');
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: location.pathname.slice(1) });
  }, [location]);

  return (
    <AppContainer>
      {showSearchAppHeader ? <AppHeader /> : <></>}

      <Routes>
        <Route index element={<HomeContainer />} />
        <Route path={ROUTE_PATHS.HOME} element={<HomeContainer />} />
        <Route path={ROUTE_PATHS.ABOUT} element={<AboutContainer />} />
        <Route path={ROUTE_PATHS.FAQ} element={<FAQContainer />} />
        {/* <Route path={ROUTE_PATHS.PRIVACY_POLICY} element={<PrivacyPolicyContainer />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <FooterContainer />
      <ScrollToTop />
    </AppContainer>
  );
};

export default App;
