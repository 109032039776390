import { FULL_WIDTH } from '../golbal-constants/sizes';

//checks of token is expired or not
export const isTokenExpired = (expireAt: number) => {
  return expireAt < new Date().getTime();
};

export const capitalize = (str: string) => {
  str = str.toLowerCase();
  return str.replace(str[0], str[0].toUpperCase());
};

export const isNullish = (str: string | undefined | null) => {
  return str === null || str === undefined || str.trim().length === 0;
};

export const extractNumFromString = (str: string) => {
  const firstNumber = str.match(/[0-9]+/);

  if (firstNumber && firstNumber[0]) {
    return firstNumber[0];
  }

  return null;
};

export const openUrl = (url: string | undefined) => {
  if (!url) {
    return;
  }
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
};

export const isMobile = () => {
  return FULL_WIDTH <= 500;
};

// check if browser supports share API
export const isShareSuppored = () => {
  return typeof navigator.share === 'function';
};
