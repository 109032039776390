import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface utilState {
  addServiceMode: boolean;
  viewServiceMode: boolean;
  serviceRemovalRequestMode: boolean;
  searchServicesMode: boolean;
}

const initialState: utilState = {
  addServiceMode: false,
  viewServiceMode: false,
  serviceRemovalRequestMode: false,
  searchServicesMode: false,
};

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    onAddService: (state, action: PayloadAction<boolean>) => {
      state.addServiceMode = action.payload;
    },
    onViewService: (state, action: PayloadAction<boolean>) => {
      state.viewServiceMode = action.payload;
    },
    onServiceRemovalRequest: (state, action: PayloadAction<boolean>) => {
      state.serviceRemovalRequestMode = action.payload;
    },
    onServiceSearch: (state, action: PayloadAction<boolean>) => {
      state.searchServicesMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onAddService, onViewService, onServiceRemovalRequest, onServiceSearch } = utilSlice.actions;

export default utilSlice.reducer;
