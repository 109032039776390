import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import utilReducer from './slices/utilSlice';
import { servicesApi } from './services/servicesApi';
import { digitalOceanApi } from './digital-ocean/digitalOceanApi';
// import { digitalOceanApi } from './digitalOcean/digialOceanApi';
export const store = configureStore({
  reducer: {
    util: utilReducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [digitalOceanApi.reducerPath]: digitalOceanApi.reducer,
  },

  //Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([servicesApi.middleware, digitalOceanApi.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// import { AppDispatch, RootState } from '../store/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
