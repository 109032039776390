import { Button, HStack, Modal, ModalBody, ModalContent, ModalFooter, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { SERVICE, SERVICE_REMOVE_REQUEST_BODY, SOCIAL_MEDIA, ServiceStatus } from '../../../store/services/type';
import { onServiceRemovalRequest } from '../../../store/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { prettyAddress } from '../../../utils/serviceUtils';
import { isMobile } from '../../../utils/utils';
import CustomCarousel from '../../common/CustomCarousel';
import ViewServiceHeader from '../view-service/ViewServiceHeader';

//icons
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useLazyServiceDeleteRequestQuery } from '../../../store/services/servicesApi';
import { CustomToast, ToastStatus } from '../../common/CustomToast';

export const MODAL_WIDTH = 450;
export const MODAL_HEIGHT = 600;

export interface SocialMediaMap {
  type: SOCIAL_MEDIA | undefined;
  link: string | undefined;
}

interface ServiceRemovalRequestModelProps {
  service: SERVICE | undefined;
}
export const ServiceRemovalRequestModel = ({ service }: ServiceRemovalRequestModelProps) => {
  const finalRef = useRef(null);
  //custom toast
  const { showToast } = CustomToast();
  //Hooks
  const [serviceRemovalRequest, { isError, isLoading }] = useLazyServiceDeleteRequestQuery();

  let [reason, setReason] = useState('');
  let [displayToast, setDislayToast] = useState(false);

  const { serviceRemovalRequestMode } = useAppSelector(state => state.util);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (displayToast) {
      setTimeout(() => {
        setDislayToast(false);
      }, 1000);
    }
  }, [displayToast]);

  const onSubmit = () => {
    if (service) {
      const request: SERVICE_REMOVE_REQUEST_BODY = {
        serviceId: service?.id,
        deletionReason: reason,
        deletionStatus: ServiceStatus.PENDING,
      };
      serviceRemovalRequest({ request });
    }
    setDislayToast(true);
    dispatch(onServiceRemovalRequest(false));
    setReason('');
  };
  return (
    <Modal
      finalFocusRef={finalRef}
      isOpen={serviceRemovalRequestMode}
      onClose={() => dispatch(onServiceRemovalRequest(false))}
      size={isMobile() ? 'md' : 'xl'}
    >
      <ModalContent borderRadius={10} className="center" width="100%" h="auto" mx={1}>
        <ModalBody p={0} width="100%" h="50%">
          {/* photos */}
          <CustomCarousel type={service?.serviceType} items={service?.imagePaths} width={getWidth()} height={300} />
          {/* service header */}
          <ViewServiceHeader
            name={service?.serviceName}
            address={prettyAddress(service?.serviceAddress)}
            phoneNumber={undefined}
            email={undefined}
          />
          {/* reason */}
          <VStack align="start" height="auto" px={4} mt={6}>
            <HStack spacing={2}>
              <Text fontSize="lg" fontFamily="montserrat" fontWeight="400">
                Reason
              </Text>
              <Tooltip hasArrow label="Reason why you're submitting removal request. " bg="#4d4d4d" color="white" placement="right">
                <span>
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
            </HStack>
            <Textarea
              value={reason}
              onChange={e => setReason(e.target.value)}
              placeholder="Reason for service removal request"
              color="#808080"
              size="sm"
              fontSize="15px"
              fontFamily="montserrat"
              fontWeight="400"
            />
          </VStack>
        </ModalBody>

        <ModalFooter mt={10}>
          <Button
            mr={10}
            style={{ width: 130, height: 40, borderRadius: 20, backgroundColor: '#c0c0c0', color: '#272829' }}
            onClick={() => dispatch(onServiceRemovalRequest(false))}
          >
            Cacel
          </Button>
          <Button
            ml={10}
            isLoading={isLoading}
            style={{ width: 130, height: 40, borderRadius: 20, backgroundColor: '#DA121A', color: '#F5F5F5' }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
      {displayToast && (
        <>
          {showToast({
            message: isError ? 'Unable to submit request. Please try again. ' : 'Youre request has been submitted successfully.',
            status: isError ? ToastStatus.ERROR : ToastStatus.SUCCESS,
          })}
        </>
      )}
    </Modal>
  );
};

const getWidth = () => {
  let width = 600;
  // if (isMobile()) {
  //   width = FULL_WIDTH;
  // }
  return width;
};
