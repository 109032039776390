import { Box, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useGetServicesQuery } from '../../store/services/servicesApi';
import ServiceCard from './ServiceCard';
import ServicesHeader from './ServicesHeader';

import { isMobile } from 'react-device-detect';
import { FULL_HEIGHT } from '../../golbal-constants/sizes';
import { SERVICE } from '../../store/services/type';
import { onServiceSearch, onViewService } from '../../store/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { filterServices, getServiceById } from '../../utils/serviceUtils';
import { AddServiceModal } from '../add-service/AddServiceModal';
import AppHeader from '../common/AppHeader';
import SearchBar from '../common/SearchBar';
import { ServiceRemovalRequestModel } from './removal-request/ServiceRemovalRequest';
import { ViewServiceModel } from './view-service/ViewServiceModal';
import { useLocation } from 'react-router-dom';

export const ServicesContainer = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  //Hooks
  const dispatch = useAppDispatch();
  const { viewServiceMode } = useAppSelector(state => state.util);
  const { data } = useGetServicesQuery();
  const query = useQuery();

  //local states
  const [clickedService, setClickedService] = useState<SERVICE>();
  const [filteredServices, setFilteredServices] = useState(data);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);
  const [serviceType, setServiceType] = useState<string | undefined>();
  const [search, setSearch] = useState<string | undefined>();
  const serviceId = query.get('service');

  useEffect(() => {
    //if modal not already opened and serviceId exists
    if (serviceId && !viewServiceMode && data) {
      setClickedService(getServiceById(data, Number(serviceId)));
      dispatch(onViewService(true));
    }
  }, [serviceId, viewServiceMode, data, dispatch]);

  useEffect(() => {
    if (data) {
      const filterServicesProps = {
        city: city,
        state: state,
        serviceType: serviceType,
        services: data,
        search: undefined,
      };
      const filtered = filterServices(filterServicesProps);
      setFilteredServices(filtered);
    }
  }, [data, city, state, serviceType, setFilteredServices]);

  const onSearch = () => {
    if (data) {
      const filterServicesProps = {
        city: city,
        state: state,
        serviceType: serviceType,
        services: data,
        search: search,
      };
      const filtered = filterServices(filterServicesProps);
      setFilteredServices(filtered);
      setSearch('');
      dispatch(onServiceSearch(false));
    }
  };

  return (
    <>
      <AppHeader>
        <SearchBar
          city={city}
          state={state}
          serviceType=""
          search={search}
          setCity={setCity}
          setState={setState}
          setServiceType={setServiceType}
          setSearch={setSearch}
          onSearch={onSearch}
        />
      </AppHeader>
      <Box className="container" width="auto" minH={FULL_HEIGHT} px={5}>
        <ServicesHeader />
        {filteredServices && filteredServices.length > 0 ? (
          <SimpleGrid columns={isMobile ? 1 : 3} spacing={0} spacingY={8} spacingX={10}>
            {filteredServices?.map(service => (
              <ServiceCard key={service.id} service={service} setClickedService={setClickedService} />
            ))}
          </SimpleGrid>
        ) : (
          <VStack className="center" w="100%" mt={100} px={4}>
            <Image src="/assets/gezana-2.png" alt="Gezana icon" w="150px" h="150px" />
            <Text fontSize="20px" fontFamily="montserrat" color="#a6a6a6">
              {data && data?.length > 0
                ? 'No results match your search criteria.'
                : 'Oops something is not right. Please refresh the page.'}
            </Text>
          </VStack>
        )}
        {/* View service modal */}
        <ViewServiceModel service={clickedService} />
        {/*  service removal request modal */}
        <ServiceRemovalRequestModel service={clickedService} />
        {/* add service modal */}
        <AddServiceModal />
      </Box>
    </>
  );
};

export default ServicesContainer;
