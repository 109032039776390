import { HStack, Input, Select, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ADDRESS } from '../../store/services/type';
import { Countries } from '../../golbal-constants/Countries';
import { isMobile } from 'react-device-detect';

interface ServiceAddressFormProps {
  isOnSubmitSetp?: boolean;
  serviceAddress: ADDRESS | undefined;
  setServiceAddress: (address: ADDRESS) => void;
}
export default function ServiceAddressForm({ isOnSubmitSetp, serviceAddress, setServiceAddress }: ServiceAddressFormProps) {
  //local state
  const [streetNumber, setStreetNumber] = useState(serviceAddress?.streetNumber);
  const [streetName, setStreetName] = useState(serviceAddress?.streetName);
  const [city, setCity] = useState(serviceAddress?.city);
  const [state, setState] = useState(serviceAddress?.state);
  const [zipcode, setZipcode] = useState(serviceAddress?.zipcode);
  const [country, setCountry] = useState(serviceAddress?.country || Countries[0].name);

  useEffect(() => {
    const address: ADDRESS = {
      streetNumber,
      streetName,
      city,
      state,
      zipcode,
      country,
    };
    setServiceAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetNumber, streetName, city, state, zipcode, country]);

  return (
    <VStack mt={isMobile ? 5 : 20} spacing={5} px={isMobile ? 5 : 20}>
      <HStack width="100%" alignItems="center" justifyContent="center" spacing={5}>
        <Input
          placeholder="Street number"
          value={streetNumber}
          height={45}
          width="30%"
          type="number"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setStreetNumber(Number(e.target.value))}
        />
        <Input
          placeholder="Street name"
          value={streetName}
          width="70%"
          height={45}
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setStreetName(e.target.value)}
        />
      </HStack>
      <HStack width="100%" alignItems="center" justifyContent="center">
        <Input
          placeholder="City"
          value={city}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setCity(e.target.value)}
        />
        <Input
          placeholder="State"
          value={state}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setState(e.target.value)}
        />
      </HStack>
      <HStack width="100%" alignItems="center" justifyContent="center">
        <Input
          placeholder="Zipcode"
          type="number"
          value={zipcode}
          height={45}
          width="45%"
          onChange={e => setZipcode(Number(e.target.value))}
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
        />
        <Select
          placeholder="Country"
          height={45}
          width="45%"
          value={country as string}
          onChange={e => setCountry(e.target.value)}
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
        >
          {Countries.map((type, index) => (
            <option key={index} value={type.name}>
              {type.name}
            </option>
          ))}
        </Select>
      </HStack>
    </VStack>
  );
}
