import { useToast } from '@chakra-ui/react';

export const enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  LOADING = 'loading',
}
export const enum ToastVariant {
  SALID = 'solid',
  SUBTLE = 'subtle',
  LEFT_ACCENT = 'left-accent',
  TOP_ACCENT = 'top-accent',
}

interface CustomToastProps {
  status?: ToastStatus | undefined;
  variant?: ToastVariant | undefined;
  message: string;
}
export const CustomToast = () => {
  const toast = useToast();

  const showToast = ({ status = ToastStatus.SUCCESS, variant = ToastVariant.LEFT_ACCENT, message }: CustomToastProps) => {
    toast({
      title: message,
      status: status as ToastStatus,
      variant: variant,
      position: 'top',
      isClosable: true,
      duration: 1000,
      containerStyle: {
        fontFamily: 'open_sans',
        fontSize: '12px',
      },
    });
  };
  return { showToast };
};
