export const FAQs: { id: number; isOpen: boolean; question: string; answer: string }[] = [
  {
    id: 0,
    isOpen: false,
    question: 'Who can add service?',
    answer: "Anyone can add service. You don't necessarily need to be the owner, as long as you know the name and address of the service.",
  },
  {
    id: 1,
    isOpen: false,
    question: 'What do I need to a add service?',
    answer:
      'The only required information to add a service is the service name and address. However, you can also provide description of the service, contact info, social media and photos.',
  },
  {
    id: 2,
    isOpen: false,
    question: 'How can I update service/bussines information?',
    answer:
      "If you'd like to update any service/bussines information contact us through either one of our social media pages or you can also email us at ourgezana@gmail.com. Please provide service name and address as well as the service info you want to update.",
  },
  {
    id: 5,
    isOpen: false,
    question: 'How can I remove existing service?',
    answer:
      "If for any reason you'd like to remove a service, you can submit a service removal request. To request service removal, click on the three vertical dots located on the service card then click on request removal. Note:  please provide a brief description as to why you're submitting the request and the Gezana team will review your request as soon as possible.",
  },
  {
    id: 6,
    isOpen: false,
    question: 'What if I am having an issue adding service?',
    answer:
      "If for some reason you're having an issue submitting a service, you can send us the service details via one of our social medias. Facebook @ Gezana ገዛና or email: ourgezana@gmail.com",
  },
];
