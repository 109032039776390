import { Box, HStack, VStack, Image, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

//icons
import { IoImage } from 'react-icons/io5';
import { CONFIGS } from '../../configs/configs';

export interface FilekeyMap {
  key: string | undefined;
  file: File | undefined;
}
interface ServicePhotosFormProps {
  isOnSubmitSetp?: boolean;
  fileKeyMap: FilekeyMap[] | undefined;
  setFileKeyMap: (e: React.SetStateAction<FilekeyMap[]>) => void;
}

export default function ServicePhotofoForm({ isOnSubmitSetp, fileKeyMap, setFileKeyMap }: ServicePhotosFormProps) {
  return (
    <VStack mt={10} spacing={5}>
      <HStack spacing={10} mb={5} width="100%" alignItems="center" justifyContent="center">
        <UploadImageCard
          setFileKeyMap={setFileKeyMap}
          filekey={(fileKeyMap && fileKeyMap[0]) || undefined}
          fileKeyMap={fileKeyMap}
          isOnSubmitSetp={isOnSubmitSetp}
        />
        <UploadImageCard
          setFileKeyMap={setFileKeyMap}
          filekey={(fileKeyMap && fileKeyMap[1]) || undefined}
          fileKeyMap={fileKeyMap}
          isOnSubmitSetp={isOnSubmitSetp}
        />
      </HStack>
      <HStack spacing={10} width="100%" alignItems="center" justifyContent="center">
        <UploadImageCard
          setFileKeyMap={setFileKeyMap}
          filekey={(fileKeyMap && fileKeyMap[2]) || undefined}
          fileKeyMap={fileKeyMap}
          isOnSubmitSetp={isOnSubmitSetp}
        />
        <UploadImageCard
          setFileKeyMap={setFileKeyMap}
          filekey={(fileKeyMap && fileKeyMap[3]) || undefined}
          fileKeyMap={fileKeyMap}
          isOnSubmitSetp={isOnSubmitSetp}
        />
      </HStack>
    </VStack>
  );
}

interface UploadImageCardProps {
  isOnSubmitSetp?: boolean;
  setFileKeyMap: (e: React.SetStateAction<FilekeyMap[]>) => void;
  filekey: FilekeyMap | undefined;
  fileKeyMap: FilekeyMap[] | undefined;
}
export const UploadImageCard = ({ isOnSubmitSetp, setFileKeyMap, filekey, fileKeyMap }: UploadImageCardProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: acceptedfileKeyMap => {
      //@ts-ignores
      const newfile = acceptedfileKeyMap[0];
      let fileMap: FilekeyMap = { key: undefined, file: newfile };

      //if key existing
      if (filekey && filekey.key && fileKeyMap) {
        const updatedFileKeyMap: FilekeyMap[] = fileKeyMap?.map(map =>
          map.key === filekey.key
            ? {
                key: filekey.key,
                file: newfile,
              }
            : { ...map }
        );

        setFileKeyMap(updatedFileKeyMap);
      }
      setFileKeyMap(prev => [...prev, fileMap]);
    },
    multiple: false,
    maxSize: 5 * 1000000, //max 5MB
  });

  return (
    <Box
      {...getRootProps({ onClick: e => (isOnSubmitSetp ? e.stopPropagation() : {}) })}
      sx={{
        width: 120,
        height: 120,
        shadow: 2,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#808080',
        flexDirection: 'column',
      }}
    >
      <input {...getInputProps({})} />
      {filekey ? (
        <Image boxSize="90%" borderRadius={10} src={getImageSrc(filekey)} alt="Service Photo" />
      ) : (
        <VStack spacing={2}>
          <IoImage fontSize="50px" color="#e6be00" />
          <Text fontSize="xs">Click to upload</Text>
        </VStack>
      )}
    </Box>
  );
};

const getImageSrc = (fileMap: FilekeyMap) => {
  if (fileMap.key) {
    //if file has been uploaded for existing key, display the uploaded file otherwise display from bucket
    return fileMap.file ? URL.createObjectURL(fileMap.file) : `${CONFIGS.DO_CDN_ENDPOINT}/${fileMap.key}`;
  }

  if (fileMap.file) {
    return URL.createObjectURL(fileMap.file);
  }
  return '';
};
