import { HStack, Input, Select, Textarea, VStack } from '@chakra-ui/react';
import { ServiceType, ServiceTypes } from '../../store/services/type';
import { prettyServiceType } from '../../utils/serviceUtils';
import { isMobile } from 'react-device-detect';

interface ServiceDetailsFormProps {
  isOnSubmitSetp?: boolean;
  serviceName: string;
  serviceType: ServiceType | undefined;
  serviceDescription: string;
  setServiceName: (value: string) => void;
  setServiceType: (type: ServiceType) => void;
  setServiceDescription: (value: string) => void;
}
export default function ServiceDetailsForm(props: ServiceDetailsFormProps) {
  const { isOnSubmitSetp, serviceName, serviceType, serviceDescription, setServiceName, setServiceType, setServiceDescription } = props;
  return (
    <VStack width="100%" mt={isMobile ? 5 : 20} spacing={5} px={isMobile ? 5 : 20}>
      <HStack width="100%" alignItems="center" justifyContent="center">
        <Input
          placeholder="Service name"
          value={serviceName}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setServiceName(e.target.value)}
        />

        <Select
          placeholder="Service type"
          value={serviceType}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setServiceType(e.target.value as ServiceType)}
        >
          {ServiceTypes.map((type, index) => (
            <option key={index} value={type}>
              {prettyServiceType(type)}
            </option>
          ))}
        </Select>
      </HStack>
      <Textarea
        placeholder="Service description"
        value={serviceDescription}
        width="100%"
        isDisabled={isOnSubmitSetp}
        _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
        onChange={e => setServiceDescription(e.target.value)}
      />
    </VStack>
  );
}
