import { Box, Select, Stack } from '@chakra-ui/react';

//icons
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdCategory } from 'react-icons/md';
import { prettyServiceType } from '../../utils/serviceUtils';

const DROP_DOWN_WIDTH = 160;
const DROP_DOWN_HEIGHT = 40;
interface DropdownProps {
  isServiceType?: boolean;
  select: { text: string; options: string[] };
  value: string | undefined;
  onChange: (e: React.SetStateAction<string>) => void;
}
const Dropdown = ({ isServiceType, select, value, onChange }: DropdownProps) => {
  return (
    <Stack
      spacing={0}
      style={{
        width: DROP_DOWN_WIDTH,
        height: DROP_DOWN_HEIGHT,
        borderRadius: 20,
        borderWidth: 1,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Box
        style={{
          width: '20px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {GetIcon({ dropDownType: select.text })}
      </Box>
      <Box w="140px" pl={1}>
        <Select placeholder={select.text} variant="unstyled" fontSize="14px" onChange={e => onChange(e.target.value)}>
          {select.options.map((option, index) => (
            <option key={index}>{isServiceType ? prettyServiceType(option) : option}</option>
          ))}
        </Select>
      </Box>
    </Stack>
  );
};

export default Dropdown;

export const GetIcon = (props: { dropDownType: string }) => {
  switch (props.dropDownType) {
    case 'Select City':
    case 'Select State':
      return <FaMapMarkerAlt color="#808080" fontSize="14px" />;
    case 'Service Type':
      return <MdCategory color="#808080" fontSize="14px" />;
    default:
      break;
  }
};
