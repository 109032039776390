import { Box, Collapse, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FULL_HEIGHT } from '../../golbal-constants/sizes';
import { FAQs } from '../../statics/faqs';

//icons
import { HiSelector } from 'react-icons/hi';

export const FAQContainer = () => {
  const [faqs, setFaqs] = useState(FAQs);

  const handleClick = (id: number) => {
    setFaqs(
      faqs.map(faq => {
        if (faq.id === id) {
          return { ...faq, isOpen: !faq.isOpen };
        } else {
          return faq;
        }
      })
    );
  };

  return (
    <VStack className="container" sx={{ minHeight: FULL_HEIGHT, mt: 75, p: 0 }}>
      <VStack width={isMobile ? '95%' : '50%'} mt={50}>
        <Text fontSize="25px" fontWeight="600" fontFamily="montserrat" textAlign="left">
          FAQ's
        </Text>
        <VStack width="100%" mt={30} borderWidth={0.5} borderColor="gray" borderRadius={10} py={5}>
          {faqs.map(faq => (
            <Box key={faq.id} width="100%">
              <Collapsable question={faq.question} answer={faq.answer} isOpen={faq.isOpen} onClick={() => handleClick(faq.id)} />
              <Divider w="95%" />
            </Box>
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default FAQContainer;

interface CollapsableProps {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
}

export const Collapsable = ({ isOpen, onClick, question, answer }: CollapsableProps) => {
  return (
    <VStack className="center" w="100%">
      <HStack w="100%" spacing={1} px={isMobile ? 2 : 5}>
        <Box w="80%" h={50} className="center" style={{ justifyContent: 'flex-start', cursor: 'pointer' }} onClick={onClick}>
          {question}
        </Box>
        <Box className="center" w="20%" h={50} style={{ justifyContent: 'flex-end' }}>
          <HiSelector size="25px" />
        </Box>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Box color="gray" mt="4" rounded="md" shadow="md" px={5}>
          {answer}
        </Box>
      </Collapse>
    </VStack>
  );
};
