import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { SERVICE, SOCIAL_MEDIA } from '../../../store/services/type';
import { onViewService } from '../../../store/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { extractContactInfo, extractSocialMedias, onShare, prettyAddress } from '../../../utils/serviceUtils';
import { isMobile, isShareSuppored, openUrl } from '../../../utils/utils';
import CustomCarousel from '../../common/CustomCarousel';
import { ServiceIndicator } from '../ServiceCard';
import ReactGA from 'react-ga4';

//icons
import { BiLogoFacebook, BiWorld } from 'react-icons/bi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { CgDetailsMore, CgMediaPodcast } from 'react-icons/cg';
import { FaInstagramSquare, FaTwitter } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { ImYoutube } from 'react-icons/im';
import { MdEmail, MdVerified, MdWebhook } from 'react-icons/md';
import { ImYelp } from 'react-icons/im';
import { FiShare } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export const MODAL_WIDTH = 450;
export const MODAL_HEIGHT = 600;

export interface SocialMediaMap {
  type: SOCIAL_MEDIA | undefined;
  link: string | undefined;
}

interface ViewServiceModelProps {
  service: SERVICE | undefined;
}
export const ViewServiceModel = ({ service }: ViewServiceModelProps) => {
  const finalRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { viewServiceMode } = useAppSelector(state => state.util);
  const contactInfo = extractContactInfo(service?.contactIfo);
  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}/?service=${service?.id}`;

  //local states
  const [showAlert, setShowAlert] = useState(false);

  const onClick = (info: string | undefined) => {
    setShowAlert(true);
    copy(info || '');
  };

  //use effect
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 1000);

      onClickTracker('Service address copied from service modal');
    }
  }, [showAlert]);

  const onClickTracker = (label: string) => {
    ReactGA.event({
      category: '',
      action: 'Click',
      label: label,
    });
  };

  return (
    <Modal finalFocusRef={finalRef} isOpen={viewServiceMode} onClose={() => dispatch(onViewService(false))} size={isMobile() ? 'md' : 'xl'}>
      <ModalContent borderRadius={10} className="center" width="100%" h="auto" mx={1}>
        <ModalBody p={0} width="100%" h="50%">
          {/* photos */}
          <CustomCarousel type={service?.serviceType} items={service?.imagePaths} width={getWidth()} height={300} />
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              top: 250,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: 3,
            }}
          >
            <ServiceIndicator serviceType={service?.serviceType} />
            {isShareSuppored() && (
              <IconButton
                colorScheme="gray"
                aria-label="Share service"
                size="md"
                icon={<FiShare fontSize="18px" />}
                onClick={() => onShare(service, fullUrl)}
              />
            )}
          </Box>
          <VStack spacing={0} className="center-left" w="100%" px={5} textAlign="left" mt={3}>
            <Text fontSize="20px" fontFamily="open_sans" fontWeight="550" noOfLines={1} alignSelf="baseline">
              {service?.serviceName}
            </Text>

            <VStack spacing={3} mt={4} w="100%">
              <DisplayServiceInfo
                type="address"
                info={prettyAddress(service?.serviceAddress)}
                onClick={() => onClick(prettyAddress(service?.serviceAddress))}
              />
              <DisplayServiceInfo
                type="phoneNumber"
                info={contactInfo?.phoneNumber?.toString()}
                onClick={() => onClick('' + contactInfo.phoneNumber)}
              />
              <DisplayServiceInfo type="email" info={contactInfo.email} onClick={() => onClick(contactInfo.email)} />
              <DisplayServiceInfo type="about" info={service?.serviceDescription} onClick={() => onClick(service?.serviceDescription)} />
              <Text fontSize="10px" color="gray">
                <b>Pro tip:</b> click on any of the service info to copy.
              </Text>
              <Wrap spacing={4} justify="center" mt={5}>
                {extractSocialMedias(service?.socialMedias).map(({ type, link }, index) => (
                  <SocialMediaIcon key={index} type={type} onClick={() => openUrl(link)} />
                ))}
              </Wrap>
            </VStack>
          </VStack>

          <Box sx={{ position: 'absolute', top: 2, right: 4 }}>
            <MdVerified style={{ color: '#DA121A', fontSize: '25px' }} />
          </Box>
        </ModalBody>

        {showAlert && (
          <Alert status="success" w={300} height={7} fontSize="12px" borderRadius={10} alignSelf="center" position="absolute" bottom="9%">
            <AlertIcon w={3} h={3} />
            copied!
          </Alert>
        )}
        <ModalFooter mt={10}>
          <Button
            mr={3}
            style={{ width: 130, height: 40, borderRadius: 20, backgroundColor: '#c0c0c0', color: '#272829' }}
            onClick={() => {
              navigate('/', { replace: true });
              dispatch(onViewService(false));
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface SocialMediaIconProps {
  type: SOCIAL_MEDIA | undefined;
  onClick: () => void;
}
export const SocialMediaIcon = ({ type, onClick }: SocialMediaIconProps) => {
  switch (type) {
    case SOCIAL_MEDIA.FACEBOOK:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="#1877F2"
          aria-label="Facebbok"
          size="sm"
          icon={<BiLogoFacebook color="white" size="25px" />}
          onClick={onClick}
          _hover={{ bg: '#1877F2' }}
        />
      );
    case SOCIAL_MEDIA.INSTAGRAM:
      return (
        <Button variant="unstyled" bg="white" size="30px" onClick={onClick}>
          <FaInstagramSquare color="red" size="30px" />
        </Button>
      );
    case SOCIAL_MEDIA.GOOGLE:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="#cccccc"
          aria-label="Google"
          size="sm"
          icon={<FcGoogle color="white" size="25px" />}
          onClick={onClick}
          _hover={{ bg: '#cccccc' }}
        />
      );
    case SOCIAL_MEDIA.YOUTUBE:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="#cccccc"
          aria-label="youtube"
          size="sm"
          icon={<ImYoutube color="red" size="20px" />}
          onClick={onClick}
          _hover={{ bg: '#cccccc' }}
        />
      );
    case SOCIAL_MEDIA.TWITTER:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="socail.twitter"
          aria-label="twitter"
          size="sm"
          icon={<FaTwitter color="white" size="20px" />}
          onClick={onClick}
          _hover={{ bg: '#cccccc' }}
        />
      );
    case SOCIAL_MEDIA.WEBSITE:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="#87CEEB"
          aria-label="website"
          size="sm"
          icon={<BiWorld color="white" size="25px" />}
          onClick={onClick}
          _hover={{ bg: '#cccccc' }}
        />
      );
    case SOCIAL_MEDIA.YELP:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="rgb(251, 67, 60)"
          aria-label="website"
          size="sm"
          icon={<ImYelp color="white" size="20px" />}
          onClick={onClick}
          _hover={{ bg: 'rgb(251, 67, 60)' }}
        />
      );
    case SOCIAL_MEDIA.OTHER:
      return (
        <IconButton
          isRound={true}
          variant="solid"
          bg="gray"
          aria-label="other"
          size="sm"
          icon={<CgMediaPodcast color="white" size="20px" />}
          onClick={onClick}
          _hover={{ bg: '#cccccc' }}
        />
      );
    default:
      return null;
  }
};

const getWidth = () => {
  let width = 600;
  // if (isMobile()) {
  //   width = FULL_WIDTH;
  // }
  return width;
};

const DisplayServiceInfo = (props: { type: string; info: string | undefined; onClick: () => void }) => {
  return props.info ? (
    <VStack spacing={0} w="100%">
      <HStack spacing={0} className="center-left" w="100%">
        <Box w="8%" h="100%">
          {getServiceInfoIcon(props.type)}
        </Box>
        <Box w="90%" h="100%">
          <Text fontSize="14px" fontWeight="400" color="gray">
            <span onClick={props.onClick}>
              {props.type === 'about' && <b> Services: </b>} {props.info}
            </span>
          </Text>
        </Box>
      </HStack>
    </VStack>
  ) : (
    <></>
  );
};

const getServiceInfoIcon = (type: string) => {
  switch (type) {
    case 'phoneNumber':
      return <BsFillTelephoneFill color="#808080" />;
    case 'email':
      return <MdEmail color="#808080" />;
    case 'address':
      return <FaLocationDot color="#808080" />;
    case 'medias':
      return <MdWebhook color="#808080" />;
    case 'about':
      return <CgDetailsMore color="#808080" />;
    default:
      break;
  }
};
