import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    color: {
      gray: '#808080',
    },
    bg: {
      primary: '#DA121A',
      main: '#E1E6E1',
      header: '#698269',
      drHeader: '#4A5B4A',
      yellow: '#e6be00',
    },
    status: {
      error: '#E33E33',
    },
    socail: {
      facebook: '#3B5998',
      instagram: '#F6635C',
      twitter: '#00ACEE',
      google: '#DD5144',
      youtube: '#c4302b',
    },
  },
  fonts: {
    montserrat: `'Montserrat Variable', sans-serif`,
    open_sans: `'Open Sans Variable', sans-serif`,
    alfa: `'Alfa Slab One', sans-serif`,
  },
});
