import { Box, Image, Text, Heading, VStack } from '@chakra-ui/react';
import { FULL_HEIGHT, FULL_WIDTH } from '../../golbal-constants/sizes';
import { isMobile } from 'react-device-detect';

export const AboutContainer = () => {
  return (
    <VStack className="container center" sx={{ minHeight: FULL_HEIGHT, mt: 75, p: 0 }}>
      <Box w="100%" h={FULL_HEIGHT - 75}>
        <Image w={FULL_WIDTH} h={FULL_HEIGHT - 100} src="/assets/about-image.jpg" alt="Gezana about" objectFit="fill" loading="eager" />
        <Box
          className="center"
          w={FULL_WIDTH}
          h={FULL_HEIGHT - 100}
          position="absolute"
          top={75}
          bg="linear-gradient(rgba(0, 0, 0, 0.600),rgba(0, 0, 0, 0.5))"
          px={5}
        >
          <Text fontSize={isMobile ? '30px' : '40px'} fontWeight="bold" color="#cccccc" fontFamily="montserrat" textAlign="center">
            Gezana means{' '}
            <Text as="mark" bg="bg.primary" fontWeight="bold" color="bg.yellow" fontFamily="montserrat" mx={2}>
              our house
            </Text>
            in Tigrigna
          </Text>
        </Box>
      </Box>
      <VStack w={isMobile ? '100%' : '50%'} px={5} my={50} spacing={5}>
        <Heading
          as="h3"
          size="2xl"
          noOfLines={1}
          textAlign="center"
          fontWeight="bold"
          fontFamily="montserrat"
          letterSpacing={1}
          color="#4d4d4d"
        >
          About Gezana <span style={{ fontSize: '30px' }}>(ገዛና)</span>
        </Heading>
        <Text textAlign="center">
          Gezna which means "<b>our house</b>" in Tigrigna is, a Tigrayan community-based platform to find nearby Tigrayan-owned services,
          such as restaurants, hotels, religious centers etc. Gezana, not only promotes economic empowerment within the Tigrayan community
          but also fosters a sense of belonging and cultural enrichment by facilitating easy access to services that reflect the rich
          traditions and heritage of the Tigrayan people.
          <br />
          <br />
          Gezana's main mission is to support and empower Tigrayan-owned businesses while also sharing the Tigrayan culture and tradition
          with the world. Through Gezana, we aim to promote cross-cultural understanding and appreciation, making Tigrayan businesses more
          accessible and encouraging the global community to explore and celebrate the unique aspects of Tigrayan culture and tradition.
        </Text>
        <br />
        <br />
      </VStack>
    </VStack>
  );
};

export default AboutContainer;
