import { FilekeyMap } from '../components/add-service/ServicePhotosForm';
import { SocialMediaMap } from '../components/services/view-service/ViewServiceModal';
import { USstates } from '../statics/usStates';
import { ADDRESS, ADD_SERVICE_REQUEST, SERVICE, SOCIAL_MEDIA, ServiceType, getServiceType } from '../store/services/type';
import { capitalize, isNullish } from './utils';

export const prettyAddress = (address: ADDRESS | undefined) => {
  if (!address) {
    return '';
  }
  const pretty =
    address.streetNumber +
    ' ' +
    address.streetName +
    ', ' +
    address.city +
    ', ' +
    getTwoLettersState(address.state) +
    ' ' +
    // address.country +
    address.zipcode;
  return pretty;
};

export const prettyServiceType = (type: string) => {
  const split = type.split('_').join(' ');

  return capitalize(split);
};

interface validateServiceDetailsProps {
  serviceName: string;
  serviceType: ServiceType | undefined;
  serviceDescription: string;
}

export const validateServiceDetails = (props: validateServiceDetailsProps): string | null => {
  const { serviceName, serviceType } = props;

  let errorMessage = null;

  if (isNullish(serviceName)) {
    errorMessage = 'Service name is required';
  } else if (isNullish(serviceType)) {
    errorMessage = 'Service type is required';
  }

  //description is not  required

  return errorMessage;
};

export const validateServiceAddress = (props: { address: ADDRESS | undefined }): string | null => {
  if (!props.address) {
    return null;
  }
  const { streetNumber, streetName, city, state, zipcode, country } = props.address;

  let errorMessage = null;

  if (isNullish(streetNumber?.toString())) {
    errorMessage = 'Street number is required';
  } else if (isNullish(streetName)) {
    errorMessage = 'Street name is required';
  } else if (isNullish(city)) {
    errorMessage = 'City is required';
  } else if (isNullish(state)) {
    errorMessage = 'State is required';
  } else if (isNullish(zipcode?.toString())) {
    errorMessage = 'zipcode is required';
  } else if (isNullish(country)) {
    errorMessage = 'Please select country';
  }

  //description is required

  return errorMessage;
};

export const stringifyMedias = (medais: SocialMediaMap[]) => {
  let res: string[] = [];
  medais.forEach(media => {
    const m = media.type?.trim() + '-' + media.link?.trim();
    res.push(m);
  });

  return res;
};

//contact info is formatted as "email-and-phonenumber"
export const extractContactInfo = (contactInfo: string | undefined): { email: string | undefined; phoneNumber: number | undefined } => {
  let res: { email: string | undefined; phoneNumber: number | undefined } = { email: undefined, phoneNumber: undefined };
  if (!contactInfo) {
    return res;
  }

  const index = contactInfo.indexOf('-and-');
  if (index === -1) {
    return res;
  }

  const email = contactInfo.slice(0, index).trim();
  const phoneNumber = contactInfo.slice(index + 5).trim(); //+5 for -and-

  if (email && email.length > 0) {
    res.email = email;
  }
  if (phoneNumber && phoneNumber.length > 0) {
    res.phoneNumber = Number(phoneNumber);
  }
  return res;
};

export const extractSocialMedias = (medias: string[] | undefined): SocialMediaMap[] => {
  let mediasMap: SocialMediaMap[] = [];
  if (!medias) {
    return mediasMap;
  }

  medias.forEach(media => {
    const index = media.indexOf('-');
    const type = media.slice(0, index) as SOCIAL_MEDIA;
    const link = media.slice(index + 1);

    if (type && link) {
      mediasMap.push({ type, link });
    }
  });
  return mediasMap;
};

export const createFileKeyMap = (fileKeys: string[]) => {
  const fileKeyMap: FilekeyMap[] = [];
  fileKeys.forEach(fileKey => {
    const map = { key: fileKey, file: undefined };
    fileKeyMap.push(map);
  });

  return fileKeyMap;
};

//email-and-phoneNumber format
export const combineContactInfo = (phoneNumber: number | undefined, email: string | undefined) => {
  let res = '-and-';

  if (email && email.trim().length > 0) {
    res = email + res;
  }

  if (phoneNumber) {
    res = res + phoneNumber;
  }

  return res;
};

export const getStatesAndCities = (services: SERVICE[], selectedState: string | undefined) => {
  const statesAndcities = groupServicesByCityAndState(services);

  let cities: string[] = [];
  let states: string[] = [];

  if (selectedState === undefined) {
    statesAndcities.forEach(item => {
      cities.push(...item.cities);
      states.push(item.state);
    });
  } else {
    statesAndcities.forEach(item => {
      if (item.state === selectedState) {
        cities.push(...item.cities);
      }
      states.push(item.state);
    });
  }

  return { cities: cities.sort(), states: states.sort() };
};

export interface filterServicesProps {
  city: string | undefined;
  state: string | undefined;
  serviceType: string | undefined;
  services: SERVICE[] | undefined;
  search: string | undefined;
}
export const filterServices = ({ city, state, serviceType, services, search }: filterServicesProps) => {
  let filteredServices = services;

  if (filteredServices) {
    if (serviceType) {
      const type = getServiceType(serviceType);

      filteredServices = filteredServices.filter(service => service.serviceType === type);
    }
    if (state) {
      filteredServices = filteredServices.filter(service => service.serviceAddress.state === state);
    }
    if (city) {
      filteredServices = filteredServices.filter(service => service.serviceAddress.city === city);
    }
  }

  if (search) {
    filteredServices = filterServicesBySearch(search, filteredServices);
  }
  return filteredServices;
};

const filterServicesBySearch = (search: string | undefined, services: SERVICE[] | undefined) => {
  if (!search) {
    return services;
  }
  let filteredServices: SERVICE[] = [];

  services?.forEach(service => {
    if (searchWord(service.serviceName, search)) {
      filteredServices.push(service);
    }
  });
  return filteredServices;
};

export const groupServicesByCityAndState = (services: SERVICE[]) => {
  let addedStates: string[] = [];
  let statesAndcities: { state: string; cities: string[] }[] = [];

  services.forEach(service => {
    const { serviceAddress } = service;
    if (serviceAddress && serviceAddress.city && serviceAddress.state) {
      if (!addedStates.includes(serviceAddress.state)) {
        const newStatesAndcities = { state: serviceAddress.state.trim(), cities: [serviceAddress.city.trim()] };
        statesAndcities.push(newStatesAndcities);
        addedStates.push(serviceAddress.state);
      } else {
        statesAndcities.forEach(item => {
          if (item.state === serviceAddress.state) {
            if (!item.cities.includes(serviceAddress.city!)) {
              item.cities = [...item.cities, serviceAddress.city?.trim()!];
            }
          }
        });
      }
    }
  });
  return statesAndcities;
};

const searchWord = (serviceName: string, search: string) => {
  const serviceNameWords = serviceName.toLowerCase().trim().split(' ');
  const searchWords = search.toLowerCase().trim().split(' ');

  for (let i = 0; i < searchWords.length; i++) {
    if (serviceNameWords.includes(searchWords[i])) {
      return true;
    }
  }
  return false;
};

export const trimAddress = (address: ADDRESS) => {
  const trimmedAddress: ADDRESS = {
    streetNumber: address.streetNumber,
    streetName: address.streetName?.trim(),
    city: address.city?.trim(),
    state: address.state?.trim(),
    country: address.country?.trim(),
    zipcode: address.zipcode,
  };

  return trimmedAddress;
};

export const sendEmail = (newService: ADD_SERVICE_REQUEST) => {
  const { serviceName: name, serviceType, serviceDescription, serviceAddress, contactIfo, socialMedias, imagePaths } = newService;
  const serviceName = 'Service: ' + name + ' - ' + serviceType;
  const description = 'Description: ' + serviceDescription;
  const address =
    'Address: ' +
    serviceAddress.streetNumber +
    ' ' +
    serviceAddress.streetName +
    ' ' +
    serviceAddress.city +
    ' ' +
    serviceAddress.country +
    ' ' +
    serviceAddress.zipcode;
  const contact = 'Contact Info: ' + contactIfo;
  const medias = 'Medias: ' + socialMedias.join(' == ');
  const fileKeys = 'Medias: ' + imagePaths.join(' == ');

  window.open(
    `mailto:ourgezana@gmail.com?subject=Gezana&body=${serviceName}%0D${description}%0D${address}%0D${contact}%0D${medias}%0D${fileKeys}%0D`
  );
};

export const getTwoLettersState = (state: string | undefined) => {
  if (state === undefined) return undefined;
  const selectedState = USstates.find(s => s.name === state);

  return selectedState?.abbreviation;
};

export const onShare = async (service: SERVICE | undefined, url: string) => {
  if (navigator.share && service) {
    try {
      await navigator.share({
        title: service.serviceName,
        text: service.serviceName,
        url: url,
      });
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.log('Share was canceled.');
      } else {
        console.error('Error sharing the link:', error);
      }
    }
  } else {
    console.log('Web Share API is not supported in your browser.');
  }
};

export const getServiceById = (services: SERVICE[], serviceId: number): SERVICE | undefined => {
  return services.find(service => service.id === serviceId);
};
