import { capitalize } from '../../utils/utils';

export interface ADD_SERVICE_REQUEST {
  serviceName: string;
  serviceType: ServiceType;
  serviceAddress: ADDRESS;
  contactIfo: string; //phonenumber-email format
  serviceDescription: string;
  socialMedias: string[]; //in type-link format e.g: Facebook-www.facebook.com
  imagePaths: string[];
}
export interface SERVICE extends ADD_SERVICE_REQUEST {
  id: number;
  serviceStatus: ServiceStatus;
}

export interface COUNTRY {
  name: string;
  code: string;
}
export interface ADDRESS {
  streetNumber: number | undefined;
  streetName: string | undefined;
  city: string | undefined;
  state: string | undefined;
  country: string | undefined;
  zipcode: number | undefined;
}

export interface SERVICE_REMOVE_REQUEST_BODY {
  serviceId: number; //maps to the service which is requested to be deleted
  deletionReason: string;
  deletionStatus: ServiceStatus;
}

//enums, must be separated by underscore if type has two or more words
export const enum ServiceType {
  HOTEL = 'HOTEL',
  RESTAURANT = 'RESTAURANT',
  CHURCH = 'CHURCH',
  // MOSQUE = 'MOSQUE',
  HAIR_SALON = 'HAIR_SALON',
  BARBER_SHOP = 'BARBER_SHOP',
  STORE = 'STORE',
  OTHER = 'OTHER',
}

export const enum ServiceStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}
export enum SOCIAL_MEDIA {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  GOOGLE = 'GOOGLE',
  WEBSITE = 'WEBSITE',
  YELP = 'YELP',
  OTHER = 'OTHER',
}

//typeservcies in array, any update to ServiceType should be added here as well
export const ServiceTypes: ServiceType[] = [
  ServiceType.BARBER_SHOP,
  ServiceType.CHURCH,
  // ServiceType.MOSQUE,
  ServiceType.HAIR_SALON,
  ServiceType.HOTEL,
  ServiceType.RESTAURANT,
  ServiceType.STORE,
  ServiceType.OTHER,
];

export const socialMediaTypes: SOCIAL_MEDIA[] = [
  SOCIAL_MEDIA.FACEBOOK,
  SOCIAL_MEDIA.GOOGLE,
  SOCIAL_MEDIA.INSTAGRAM,
  SOCIAL_MEDIA.TWITTER,
  SOCIAL_MEDIA.WEBSITE,
  SOCIAL_MEDIA.YOUTUBE,
  SOCIAL_MEDIA.YELP,
  SOCIAL_MEDIA.OTHER,
];

export const getServiceType = (serviceType: string): ServiceType | undefined => {
  switch (serviceType) {
    case 'Restaurant':
      return ServiceType.RESTAURANT;
    case 'Hotel':
      return ServiceType.HOTEL;
    case 'Hair salon':
      return ServiceType.HAIR_SALON;
    case 'Barber shop':
      return ServiceType.BARBER_SHOP;
    case 'Church':
      return ServiceType.CHURCH;
    // case 'Mosque':
    //   return ServiceType.MOSQUE;
    case 'Store':
      return ServiceType.STORE;
    case 'Other':
      return ServiceType.OTHER;

    default:
      return undefined;
  }
};

export const capitalizedTypeServices = () => {
  let types: string[] = [];
  ServiceTypes.forEach(type => {
    let temp = type.split('_');
    if (temp && temp.length > 0) {
      let word = '';
      temp.forEach((t, index) => {
        if (index === 0) {
          word += capitalize(t + ' ');
        } else {
          word += t.toLowerCase() + ' ';
        }
      });
      types.push(word);
    }
  });

  return types;
};
