import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';

interface ServiceSubmitionProps {
  ServiceDetails: JSX.Element;
  ServiceAddress: JSX.Element;
  ServiceContactInfo: JSX.Element;
  ServicePhotos: JSX.Element;
}

export default function ServiceSubmition({ ServiceDetails, ServiceAddress, ServiceContactInfo, ServicePhotos }: ServiceSubmitionProps) {
  return (
    <VStack width="100%" mt={10} display="flex" alignItems="center" justifyContent="center">
      <Tabs width="100%" isLazy variant="enclosed" align="center">
        <TabList>
          <Tab _selected={{ bg: '#e6be00' }}>Service Details</Tab>
          <Tab _selected={{ bg: '#e6be00' }}>Address</Tab>
          <Tab _selected={{ bg: '#e6be00' }}>Contact Info</Tab>
          <Tab _selected={{ bg: '#e6be00' }}>Photos</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>{ServiceDetails}</TabPanel>
          <TabPanel>{ServiceAddress}</TabPanel>
          <TabPanel>{ServiceContactInfo}</TabPanel>
          <TabPanel>{ServicePhotos}</TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
