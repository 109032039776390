import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { onAddService } from '../../store/slices/utilSlice';
import { useAppDispatch } from '../../store/store';

//icons
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';

export default function ServiceSubmitionConfirmation() {
  const dispatch = useAppDispatch();
  return (
    <VStack width="100%" display="flex" alignItems="center" justifyContent="center">
      <FaEnvelopeOpenText fontSize="100px" color="#FFD300" />
      <Heading mt={3} fontSize="30px">
        You're all done!
      </Heading>
      <Center width="80%" mt={3} flexDirection="column">
        <Text fontSize="md" align="center">
          Thank you for adding service! To avoid spammers new services are reviewed and verified. We usually review new services within 48
          hours. To check if your service has been approved, on the home page, search for your service and if you don't see it there in 2
          days, it's probably wasn't approved.
          <span style={{ fontSize: '14px' }}> Should you have any questions or concerns, please don't hestitate to contact us.</span>
        </Text>
        <br />
        <br />
        {/* <br />
        <br /> */}
        <Text fontSize="md" fontWeight="bold">
          Let's support and empower our own bussinesses!
        </Text>
      </Center>

      <Box mt={isMobile ? 50 : 100}>
        <Button
          bg="bg.primary"
          color="bg.main"
          variant="solid"
          width={200}
          onClick={() => dispatch(onAddService(false))}
          _hover={{ backgroundColor: undefined }}
        >
          DONE
        </Button>
      </Box>
    </VStack>
  );
}
