import { Box, Image, Heading, VStack, Text } from '@chakra-ui/react';
import { FULL_HEIGHT } from '../../golbal-constants/sizes';
import { isMobile } from 'react-device-detect';

export const PageNotFound = () => {
  return (
    <Box className="container center" sx={{ minHeight: FULL_HEIGHT, mt: 70, px: 5 }}>
      <VStack className="center" mt={0} p={0} w={isMobile ? '100%' : '50%'} spacing={0}>
        <Image src="/assets/gezana-2.png" alt="Gezana icon" w="150px" h="150px" />
        <Heading mt={10} as="h1" size={isMobile ? '3xl' : '4xl'} fontFamily="montserrat" color="#e6e6e6" p={0}>
          404
        </Heading>
        <Text
          mt={10}
          mb={4}
          fontSize={isMobile ? '20px' : '30px'}
          fontWeight="bold"
          fontFamily="montserrat"
          color="black"
          textAlign="center"
        >
          You have found a secret place.
        </Text>
        <Text fontSize={isMobile ? '18px' : '20px'} fontFamily="montserrat" color="#a6a6a6" textAlign="center">
          Unfortunately, this is only a 404 page. You may have mistyped the address. Please try refreshing the page.
        </Text>
      </VStack>
    </Box>
  );
};
