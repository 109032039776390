import { HStack, VStack, Text, Image, Button, IconButton } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga4';

//icons
import { MdEmail } from 'react-icons/md';
import { BiLogoFacebook } from 'react-icons/bi';
import { FaInstagramSquare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../routes/RoutePaths';
import { openUrl } from '../../utils/utils';

const FooterContainer = () => {
  const navigate = useNavigate();

  const onClickTracker = (label: string) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: label,
    });
  };

  return (
    <VStack className="center" w="100%" minH="400px" bg="#595959" mt="40px" py="30px">
      <HStack>
        <Image w="80%" h="80%" objectFit="contain" boxSize="45px" src="/assets/two.png" alt="Gezana icon" />
        <Text sx={{ fontSize: '25px', fontWeight: '600', fontFamily: 'open_sans', color: '#e6e6e6' }}>Gezana - ገዛና</Text>
      </HStack>
      <VStack w={isMobile ? '100%' : '60%'} h="250px" borderWidth={1} borderColor="#e6be00" borderRadius={15} mt={5} pb={5}>
        <HStack w="100%" h="100%">
          <VStack className="center" w="50%" h="80%" spacing={3}>
            <Button color="#e6e6e6" fontSize="14px" variant="unstyled" h="auto" onClick={() => navigate(ROUTE_PATHS.HOME)}>
              Gezana
            </Button>
            <Button color="#e6e6e6" fontSize="14px" variant="unstyled" h="auto" onClick={() => navigate(ROUTE_PATHS.ABOUT)}>
              About
            </Button>
            <Button color="#e6e6e6" fontSize="14px" variant="unstyled" h="auto" onClick={() => navigate(ROUTE_PATHS.FAQ)}>
              FAQs
            </Button>
            {/* <Button color="#e6e6e6" fontSize="14px" variant="unstyled" h="auto" onClick={() => navigate(ROUTE_PATHS.PRIVACY_POLICY)}>
              Privacy Policy
            </Button> */}
          </VStack>
          <VStack className="center" w="50%" h="80%">
            <HStack spacing={10}>
              <IconButton
                isRound={true}
                variant="solid"
                bg="#1877F2"
                aria-label="Facebbok"
                size="sm"
                icon={<BiLogoFacebook color="white" size="25px" />}
                onClick={() => {
                  onClickTracker('Facebook icon from footer');
                  openUrl('https://www.facebook.com/profile.php?id=61551340276009');
                }}
                _hover={{ bg: '#1877F2' }}
              />
              <Button
                variant="unstyled"
                bg="white"
                size="30px"
                onClick={() => {
                  onClickTracker('Instagram icon from footer');
                  openUrl('https://www.instagram.com/gezana__');
                }}
              >
                <FaInstagramSquare color="red" size="30px" />
              </Button>
            </HStack>
            <HStack className="center" width="100%" height="30%">
              <MdEmail color="gray" />
              <Text fontSize="14px" fontWeight="400" color="#e6e6e6">
                ourgezana@gmail.com
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Text fontSize="16px" fontWeight="Montserrat" color="#b3b3b3">
          © {new Date().getFullYear()} Gezana. All rights reserved.
        </Text>
      </VStack>
    </VStack>
  );
};

export default FooterContainer;
