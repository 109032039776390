import { Image } from '@chakra-ui/react';
import Carousel from 'react-elastic-carousel';
import { CONFIGS } from '../../configs/configs';
import { ServiceType } from '../../store/services/type';

interface CarouselProps {
  items: string[] | undefined;
  width: number;
  height: number;
  type: ServiceType | undefined;
}

const CustomCarousel = ({ items, width, height, type }: CarouselProps) => {
  return (
    //@ts-ignore
    <Carousel showArrows={false} enableAutoPlay pagination={false} itemPadding={[0, 0, 0, 0]}>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <Image
            key={index}
            width={width}
            h={height}
            objectFit="cover"
            src={`${CONFIGS.FLASK_ENDPOINT}/${item}.jpeg`} //
            fallbackSrc={getPlaceholderImage(type)}
            alt="Service Image"
            style={{}}
          />
        ))
      ) : (
        <Image
          width={width}
          h={height}
          objectFit="cover"
          src={undefined}
          fallbackSrc={getPlaceholderImage(type)}
          alt="Service Image"
          style={{}}
        />
      )}
    </Carousel>
  );
};

export default CustomCarousel;

export const getPlaceholderImage = (type: ServiceType | undefined) => {
  switch (type) {
    case ServiceType.HOTEL:
      return '/assets/place-holders/hotel.png';
    case ServiceType.RESTAURANT:
      return '/assets/place-holders/restaurant.png';
    case ServiceType.CHURCH:
      return '/assets/place-holders/church.png';
    // case ServiceType.MOSQUE:
    //   return '/assets/place-holders/mosque.png';
    case ServiceType.BARBER_SHOP:
      return '/assets/place-holders/barbershop.png';
    case ServiceType.HAIR_SALON:
      return '/assets/place-holders/hair-salon.png';
    case ServiceType.STORE:
      return '/assets/place-holders/store.png';
    default:
      return '/assets/place-holders/other.png';
  }
};
