import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  useSteps,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSaveFilesMutation } from '../../store/digital-ocean/digitalOceanApi';
import { ADDRESS, ADD_SERVICE_REQUEST, ServiceType } from '../../store/services/type';
import { onAddService } from '../../store/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  combineContactInfo,
  sendEmail,
  stringifyMedias,
  trimAddress,
  validateServiceAddress,
  validateServiceDetails,
} from '../../utils/serviceUtils';
import { CustomToast, ToastStatus } from '../common/CustomToast';
import ServiceAddressForm from './ServiceAddressForm';
import ServiceContaictInfoForm, { SocialMediaMap } from './ServiceContactInfoForm';
import ServiceDetailsForm from './ServiceDetailsForm';
import ServicePhotofoForm, { FilekeyMap } from './ServicePhotosForm';
import ServiceSubmition from './ServiceSubmition';
import ServiceSubmitionConfirmation from './ServiceSubmtionConfirmation';

const steps = [{ title: 'Service Details' }, { title: 'Address' }, { title: 'Contact Info' }, { title: 'Photos' }, { title: 'Submit' }];
export const AddServiceModal = () => {
  const { activeStep, goToPrevious, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const dispatch = useAppDispatch();
  const { addServiceMode } = useAppSelector(state => state.util);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  //custom toast
  const { showToast } = CustomToast();

  //local states
  const [serviceName, setServiceName] = useState('');
  const [serviceType, setServiceType] = useState<ServiceType | undefined>();
  const [serviceDescription, setServiceDescription] = useState('');
  const [serviceAddress, setServiceAddress] = useState<ADDRESS | undefined>();
  const [servicePhoneNumber, setServicePhoneNumber] = useState<number | undefined>();
  const [serviceEmail, setServiceEmail] = useState<string>();
  const [serviceSocialMedias, setServiceSocialMedias] = useState<SocialMediaMap[]>([]);
  const [fileKeyMap, setFileKeyMap] = useState<FilekeyMap[]>([]); //maps file to fileKey on the bucket

  //Hooks
  const [saveFiles, { isLoading: fileSavingOnprogress }] = useSaveFilesMutation();

  const onNext = async (currStep: number) => {
    let errorMessage = null;

    if (currStep === 0) {
      //validate service details
      errorMessage = validateServiceDetails({ serviceName, serviceType, serviceDescription });
    } else if (currStep === 1) {
      //validate service address
      errorMessage = validateServiceAddress({ address: serviceAddress });
    } //step 2 and 3 aren't required
    else if (currStep === 4) {
      //save to digital ocean
      const data = new FormData();
      fileKeyMap.forEach(fileKey => {
        if (fileKey && fileKey?.file) {
          data.append('files', fileKey.file);
        }
      });
      let fileKeys: string[] = [];
      if (data && data.getAll('files').length > 0) {
        fileKeys = await saveFiles({ files: data }).unwrap();
      }

      let newService: ADD_SERVICE_REQUEST = {
        serviceName: serviceName.trim(),
        serviceAddress: trimAddress(serviceAddress!),
        serviceType: serviceType!,
        contactIfo: combineContactInfo(servicePhoneNumber, serviceEmail),
        serviceDescription: serviceDescription.trim(),
        socialMedias: stringifyMedias(serviceSocialMedias),
        imagePaths: fileKeys,
      };

      sendEmail(newService);
      // addService({ newService: newService })
      //   .unwrap()
      //   .then(success => {
      //     showToast({ message: 'Service addded succssfully' });
      //     setTimeout(() => {
      //       //auto next
      //       goToNext();
      //     }, 100);
      //   })
      //   .catch(error => {});
    }

    if (errorMessage !== null) {
      showToast({ message: errorMessage, status: ToastStatus.ERROR });
      return;
    }

    //else move on next step
    goToNext();
  };

  //on unmount
  useEffect(() => {
    if (!addServiceMode) {
      setActiveStep(0);
      setServiceName('');
      setServiceType(undefined);
      setServiceDescription('');
      setServiceAddress(undefined);
      setServicePhoneNumber(undefined);
      setServiceEmail('');
      setServiceSocialMedias([]);
      setFileKeyMap([]);
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addServiceMode]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={addServiceMode}
      onClose={() => dispatch(onAddService(false))}
      size="full"
    >
      <ModalContent className="center">
        <ModalHeader>Add Service</ModalHeader>
        <ModalCloseButton sx={{ borderWidth: 1, borderColor: 'bg.primary' }} />
        <ModalBody sx={{ width: isMobile ? '100%' : '50%', height: 'auto', mt: 10, p: isMobile ? 3 : 10 }}>
          {/* steppers */}
          {!isMobile && activeStep < 5 && (
            <HStack className="center" w="100%" height="auto">
              <Stepper width="100%" index={activeStep} pl={isMobile ? 0 : 10} colorScheme="red">
                {steps.map((step, index) => (
                  <Box key={index} width="100%" flexDirection="column" display="flex" justifyContent="center" alignItems="center">
                    <Step key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <HStack width={isMobile ? 15 : 100}>
                        <StepIndicator bg="gb.main">
                          {' '}
                          <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                        </StepIndicator>
                        <StepSeparator style={{}} />
                      </HStack>
                    </Step>
                    {!isMobile && (
                      <Box width="auto" display="flex" justifyContent="center" alignItems="center" position="absolute" pr={14} pt={50}>
                        <Text style={{ fontSize: '14px' }}>{step.title}</Text>
                      </Box>
                    )}
                  </Box>
                ))}
              </Stepper>
            </HStack>
          )}

          {/* body */}
          {activeStep < 5 && (
            <VStack mt={isMobile ? 0 : 10} sx={{ width: '100%', height: 'auto', borderRadius: 20, borderWidth: 1, borderColor: '#cccccc' }}>
              {/* step title */}
              <HStack w="100%">
                <StepTitle title={steps[activeStep].title} />
                {isMobile && (
                  <Box w="50px" h="40px" bg="bg.yellow" alignSelf="flex-start" sx={{ borderTopEndRadius: 19 }}>
                    <Text align="center" pt={2} color="bg.main">
                      {activeStep + 1}/{steps.length}
                    </Text>
                  </Box>
                )}
              </HStack>
              {activeStep === 0 && (
                <ServiceDetailsForm
                  serviceName={serviceName}
                  serviceType={serviceType}
                  serviceDescription={serviceDescription}
                  setServiceName={setServiceName}
                  setServiceType={setServiceType}
                  setServiceDescription={setServiceDescription}
                />
              )}
              {activeStep === 1 && <ServiceAddressForm serviceAddress={serviceAddress} setServiceAddress={setServiceAddress} />}
              {activeStep === 2 && (
                <ServiceContaictInfoForm
                  isOnSubmitSetp={false}
                  servicePhoneNumber={servicePhoneNumber}
                  serviceEmail={serviceEmail}
                  serviceSocialMedias={serviceSocialMedias}
                  setServicePhoneNumber={setServicePhoneNumber}
                  setServiceEmail={setServiceEmail}
                  setServiceSocialMedias={setServiceSocialMedias}
                />
              )}
              {activeStep === 3 && <ServicePhotofoForm fileKeyMap={fileKeyMap} setFileKeyMap={setFileKeyMap} />}
              {activeStep === 4 && (
                <ServiceSubmition
                  ServiceDetails={
                    <ServiceDetailsForm
                      serviceName={serviceName}
                      serviceType={serviceType}
                      serviceDescription={serviceDescription}
                      setServiceName={setServiceName}
                      setServiceType={setServiceType}
                      setServiceDescription={setServiceDescription}
                      isOnSubmitSetp
                    />
                  }
                  ServiceAddress={
                    <ServiceAddressForm serviceAddress={serviceAddress} setServiceAddress={setServiceAddress} isOnSubmitSetp />
                  }
                  ServiceContactInfo={
                    <ServiceContaictInfoForm
                      servicePhoneNumber={servicePhoneNumber}
                      serviceEmail={serviceEmail}
                      serviceSocialMedias={serviceSocialMedias}
                      setServicePhoneNumber={setServicePhoneNumber}
                      setServiceEmail={setServiceEmail}
                      setServiceSocialMedias={setServiceSocialMedias}
                      isOnSubmitSetp
                    />
                  }
                  ServicePhotos={<ServicePhotofoForm fileKeyMap={fileKeyMap} setFileKeyMap={setFileKeyMap} isOnSubmitSetp />}
                />
              )}
              {/* Footer */}
              <Footer
                isLoading={fileSavingOnprogress}
                activeStep={activeStep}
                onBack={() => goToPrevious()}
                onNext={() => onNext(activeStep)}
              />
            </VStack>
          )}
          {activeStep > 4 && (
            <VStack sx={{ width: '100%', height: 'auto' }}>
              <ServiceSubmitionConfirmation />
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface FooterProps {
  isLoading: boolean;
  activeStep: number;
  onNext: () => void;
  onBack: () => void;
}

export const Footer = ({ isLoading, activeStep, onNext, onBack }: FooterProps) => {
  return (
    <HStack width="100%" display="flex" alignItems="center" justifyContent="space-around" mt={20} mb={5} px={isMobile ? 5 : 20}>
      <Button
        borderColor={activeStep === 0 ? 'gray' : 'bg.primary'}
        color="#666666"
        variant="outline"
        width="50%"
        isDisabled={activeStep === 0}
        onClick={onBack}
        _hover={{ backgroundColor: undefined }}
      >
        Back
      </Button>

      <Button
        isLoading={isLoading}
        bg="bg.primary"
        color="bg.main"
        variant="solid"
        width="50%"
        onClick={onNext}
        _hover={{ backgroundColor: undefined }}
      >
        {activeStep === 4 ? 'Submit' : 'Next'}
      </Button>
    </HStack>
  );
};

export const StepTitle = (props: { title: string }) => {
  return (
    <Box width="100%" px={18} pt={5}>
      <Heading fontSize="20px" fontWeight="600" color="gray" fontFamily="open_sans">
        {props.title === 'Submit' ? 'Review' : props.title}
      </Heading>
    </Box>
  );
};
