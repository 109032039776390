import { Button, HStack, Heading, Input, InputGroup, InputRightElement, Select, SimpleGrid, Text, Tooltip, VStack } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import { capitalize } from '../../utils/utils';
import { SOCIAL_MEDIA, socialMediaTypes } from '../../store/services/type';

//icons
import { isMobile } from 'react-device-detect';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';
import { CgMediaPodcast } from 'react-icons/cg';
import { IoLogoYoutube } from 'react-icons/io';
import { BiWorld } from 'react-icons/bi';
import { ImYelp } from 'react-icons/im';

export interface SocialMediaMap {
  type: SOCIAL_MEDIA | undefined;
  link: string | undefined;
}
interface ServiceContactInfoFormProps {
  isOnSubmitSetp?: boolean;
  servicePhoneNumber: number | undefined;
  serviceEmail: string | undefined;
  serviceSocialMedias: SocialMediaMap[];
  setServicePhoneNumber: (num: number) => void;
  setServiceEmail: (email: string) => void;
  setServiceSocialMedias: Dispatch<SetStateAction<SocialMediaMap[]>>;
}
export default function ServiceContaictInfoForm(props: ServiceContactInfoFormProps) {
  const {
    isOnSubmitSetp,
    servicePhoneNumber,
    serviceEmail,
    serviceSocialMedias,
    setServiceEmail,
    setServicePhoneNumber,
    setServiceSocialMedias,
  } = props;
  const [socialType, setSocialType] = useState<SocialMediaMap>();

  const onSave = () => {
    if (socialType && socialType.link && socialType.type) {
      setServiceSocialMedias(prev => [...prev, socialType]);
    }

    //on success reset
    setSocialType(undefined);
  };

  const onRemoveSocailMedia = (type: SOCIAL_MEDIA | undefined) => {
    if (type && !isOnSubmitSetp) {
      const filtered = serviceSocialMedias.filter(media => media.type !== type);
      setServiceSocialMedias(filtered);
    }
  };
  return (
    <VStack width="100%" mt={isMobile ? 5 : 20} spacing={5} px={isMobile ? 5 : 20}>
      <HStack width="100%" spacing={5}>
        <Input
          placeholder="Phone number"
          type="number"
          value={servicePhoneNumber}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setServicePhoneNumber(Number(e.target.value))}
        />
        <Input
          placeholder="email address"
          type="email"
          value={serviceEmail}
          height={45}
          width="50%"
          isDisabled={isOnSubmitSetp}
          _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
          onChange={e => setServiceEmail(e.target.value)}
        />
      </HStack>
      <VStack width="100%" alignItems="flex-start" mt={10} spacing={0}>
        <HStack width="100%" alignItems="center" mb={5} spacing={1}>
          <Heading width="auto" fontSize="23px" color="gray">
            Social Media
          </Heading>
          <Text width="auto" fontSize="xs" fontWeight="400" color="#808080">
            (You can add multiple social medias)
          </Text>
        </HStack>
        <HStack width="100%" alignItems="center" justifyContent="center">
          <Select
            placeholder="Select"
            height={45}
            width="30%"
            isDisabled={isOnSubmitSetp}
            _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
            onChange={e => setSocialType({ type: e.target.value as SOCIAL_MEDIA, link: socialType?.link || '' })}
          >
            {socialMediaTypes.map((media, index) => (
              <option key={index} value={media} disabled={serviceSocialMedias.some(m => m.type === media)}>
                {capitalize(media)}
              </option>
            ))}
          </Select>

          <InputGroup height={45} width="70%">
            <Input
              placeholder="Enter link here"
              type="url"
              height={45}
              width="100%"
              value={socialType?.link || ''}
              isDisabled={isOnSubmitSetp}
              _disabled={{ color: '#808080', borderColor: '#B8B8B8' }}
              onChange={e => setSocialType({ type: socialType?.type || undefined, link: e.target.value })}
            />
            <InputRightElement
              height={45}
              width="20%"
              children={
                <Button
                  height={10}
                  color="#4d4d4d"
                  borderRadius={10}
                  _hover={{ backgroundColor: undefined }}
                  bg="#e6be00"
                  onClick={() => onSave()}
                  isDisabled={!socialType || !socialType.link || !socialType.type || isOnSubmitSetp}
                  _disabled={{ bg: '#e6be00' }}
                >
                  Save
                </Button>
              }
            />
          </InputGroup>
        </HStack>
      </VStack>
      <HStack>
        <SimpleGrid columns={isMobile ? 2 : 3} spacing={5}>
          {serviceSocialMedias?.map((media, index) => (
            <SocialMediaIcon
              key={index}
              type={media.type}
              isOnSubmitSetp={isOnSubmitSetp}
              onClick={() => onRemoveSocailMedia(media.type)}
            />
          ))}
        </SimpleGrid>
      </HStack>
    </VStack>
  );
}

interface SocialMediaIconProps {
  isOnSubmitSetp?: boolean;
  disableTooltip?: boolean;
  type: SOCIAL_MEDIA | undefined;
  onClick: () => void;
}
export const SocialMediaIcon = ({ isOnSubmitSetp, disableTooltip, type, onClick }: SocialMediaIconProps) => {
  switch (type) {
    case SOCIAL_MEDIA.FACEBOOK:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="socail.facebook"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'socail.facebook' }}
            leftIcon={<FaFacebook />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Facebook
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.INSTAGRAM:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="socail.instagram"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'socail.instagram' }}
            leftIcon={<BsInstagram />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Instagram
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.GOOGLE:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="socail.google"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'socail.google' }}
            leftIcon={<AiOutlineGoogle fontSize="20px" />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Google
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.YOUTUBE:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="socail.youtube"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'socail.youtube' }}
            leftIcon={<IoLogoYoutube />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Youtube
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.TWITTER:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="socail.twitter"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'socail.twitter' }}
            leftIcon={<FaTwitter />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Twitter
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.WEBSITE:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="#87CEEB"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: '#87CEEB' }}
            leftIcon={<BiWorld size="23px" />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Website
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.YELP:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="rgb(251, 67, 60)"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'rgb(251, 67, 60)' }}
            leftIcon={<ImYelp size="23px" />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Yelp
          </Button>
        </Tooltip>
      );
    case SOCIAL_MEDIA.OTHER:
      return (
        <Tooltip hasArrow label={isOnSubmitSetp || disableTooltip ? undefined : 'Click to remove'} bg="bg.header">
          <Button
            bg="gray"
            color="bg.main"
            isDisabled={isOnSubmitSetp}
            _disabled={{ bg: 'gray' }}
            leftIcon={<CgMediaPodcast />}
            _hover={{ bg: undefined }}
            onClick={onClick}
          >
            Other
          </Button>
        </Tooltip>
      );
    default:
      return null;
  }
};
